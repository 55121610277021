<template>
  <div class="container">
    <div class="steps">
      <!-- active从0开始计 -->
      <el-steps :active="selectActive" align-center>
        <el-step title="参赛作品">
          <i
            slot="icon"
            style="font-size: 20px"
            class="iconfont icon-yonghuxinxi"
          />
        </el-step>
        <el-step title="作品信息">
          <i slot="icon" style="font-size: 20px" class="iconfont icon-tupian" />
        </el-step>
        <el-step title="承诺书">
          <i
            slot="icon"
            style="font-size: 20px"
            class="iconfont icon-chengnuo"
          />
        </el-step>
        <el-step title="下载报名表">
          <i
            slot="icon"
            style="font-size: 20px"
            class="iconfont icon-gaizhang"
          />
        </el-step>
        <!-- <el-step title="完成">
          <i
            slot="icon"
            style="font-size: 20px"
            class="iconfont icon-wancheng"
          />
        </el-step> -->
      </el-steps>
    </div>

    <div class="info-box">
      <!-- 参赛信息 -->
      <div v-if="selectActive == 0">
        <!-- 标题 -->
        <div class="title">
          <i style="font-size: 25px" class="iconfont icon-yonghuxinxi" />
          <span class="title-text">参赛信息</span>
        </div>

        <div class="body">
          <WorkInfo :preform="form" @form="form = $event" />
        </div>
      </div>

      <!-- 作品信息 -->
      <div v-if="selectActive == 1">
        <div class="title">
          <i style="font-size: 25px" class="iconfont icon-tupian" />
          <span class="title-text">作品信息</span>
        </div>
        <div class="body">
          <EnrollInfo
            :preform="form"
            :is-pic-upload="isPicUpload"
            :is-model-upload="isModelUpload"
            :is-video-upload="isVideoUpload"
            @form="form = $event"
          />
        </div>
      </div>

      <!-- 承诺书 -->
      <div v-if="selectActive == 2">
        <div class="title">
          <i style="font-size: 25px" class="iconfont icon-chengnuo" />
          <span class="title-text">承诺书</span>
        </div>

        <div class="body">
          <Commitment :pre-is-consent="isConsent" @isConsent="isConsent = $event" />
        </div>
      </div>

      <!-- 上传报名表 -->
      <div v-if="selectActive == 3">
        <!-- 标题 -->
        <div class="title">
          <i style="font-size: 25px" class="iconfont icon-gaizhang" />
          <span class="title-text">下载报名表</span>
        </div>

        <div class="body">
          <Form :work-id="form.id" />
        </div>
      </div>

      <!-- 完成 -->
      <!-- <div v-if="selectActive == 4">
        <div class="title">
          <i style="font-size: 25px" class="iconfont icon-wancheng" />
          <span class="title-text">完成</span>
        </div>

        <div class="body">
          <Form />
        </div>
      </div> -->

      <div v-if="selectActive != 0 && selectActive != 1" class="go-next">
        <!-- <div v-if="selectActive!=0" class="icon" @click="goForward">
          <svg-icon name="forward" width="50" height="20"></svg-icon>
        </div>
        <div v-else class="icon">
          <svg-icon width="50" height="20"></svg-icon>
        </div> -->
        <el-button v-if="selectActive == 2" type="primary" class="btn" @click="goForward">
          上一步
        </el-button>
        <el-button
          v-if="selectActive == 2"
          type="primary"
          class="btn"
          @click="onSave"
        >
          完成报名
        </el-button>
        <el-button v-else type="primary" class="btn" @click="goCheckEnrollInfo">
          查看报名信息
        </el-button>
        <!-- <div v-if="selectActive!=3" class="icon" @click="goBackward">
          <svg-icon name="backward" width="50" height="20"></svg-icon>
        </div>
        <div v-else class="icon" @click="goBackward">
          <svg-icon width="50" height="20"></svg-icon>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import '@/svg/iconfont/iconfont.css'
import '@/icons/forward'
import '@/icons/backward'
import Commitment from './subviews/commitment'
import EnrollInfo from './subviews/enrollinfo'
import WorkInfo from './subviews/workinfo'
import Form from './subviews/form'
import { registration, fetchContestInfo } from '@/api/contest'
import dayjs from 'dayjs'

export default {
  components: { Commitment, EnrollInfo, WorkInfo, Form },
  data() {
    return {
      processContent: [
        { title: '参赛信息' },
        { title: '作品信息' },
        { title: '承诺书' },
        { title: '下载报名表' },
        { title: '完成' }
      ],
      // processActive: 3,
      selectActive: 0,
      // 大赛id
      contestId: null,

      // 参赛信息
      form: {
        workName: '', // 作品名称
        authorName: '', // 作者姓名
        birthday: '', // 出生日期
        gender: '', // 姓别
        identityNumber: '', // 身份证号
        studentType: '', // 学生类别
        phone: '', // 手机号码
        email: '', // 作者邮箱
        groupMember: '', // 小组成员（非必须）
        racetrack: '', // 参赛通道
        category: '', // 参赛类别
        university: '', // 所属高校
        department: '', // 所在院系
        grade: '', // 所在年级
        studentNumber: '', // 作者学号
        teacherName: '', // 指导老师
        address: '', // 通讯地址
        specification: '', // 说明
        posterSrc: '', // 平面图url
        modelSrc: '', // 模型url
        videoSrc: '', // 视频url
        id: null,
        contestName: '',
        acceptance: '',
        contestId: parseInt(this.$route.params.contestId),
        studentId: null,
        boardId: null
      },

      // 说明、平面图、视频、模型信息
      specification: '',
      posterSrc: '',
      modelSrc: '',
      videoSrc: '',
      isPicUpload: true,
      isModelUpload: true,
      isVideoUpload: true,
      // 同意承诺书
      isConsent: 0,
      isSealed: 0,
      studentId: ''
    }
  },
  created() {
    this.contestId = this.$route.params.contestId
    // localStorage.clear()
    // 存储enrollform
    if (localStorage.getItem(this.contestId + '_EnrollForm') == null) {
      this.setForm()
    } else {
      this.getForm()
    }
    // 存储承诺书勾选项
    if (localStorage.getItem(this.contestId + '_CommitConsent') == null) {
      localStorage.setItem(this.contestId + '_CommitConsent', this.isConsent)
    } else {
      const consent = localStorage.getItem(this.contestId + '_CommitConsent')
      this.isConsent = consent === '1'
    }
    // 步骤进度
    if (localStorage.getItem(this.contestId + '_step') == null) {
      localStorage.setItem(this.contestId + '_step', this.selectActive)
    } else {
      this.selectActive = parseInt(localStorage.getItem(this.contestId + '_step'))
    }
    if (this.form.posterSrc === '') {
      this.isPicUpload = false
    }
    if (this.form.modelSrc === '') {
      this.isModelUpload = false
    }
    if (this.form.videoSrc === '') {
      this.isVideoUpload = false
    }
  },
  mounted() {
    this.contestId = this.$route.params.contestId
    console.log('contestid', this.contestId)
    // 存储enrollform
    if (localStorage.getItem(this.contestId + '_EnrollForm') == null) {
      this.setForm()
    } else {
      this.getForm()
    }
    // 存储承诺书勾选项
    if (localStorage.getItem(this.contestId + '_CommitConsent') == null) {
      localStorage.setItem(this.contestId + '_CommitConsent', this.isConsent)
    } else {
      const consent = localStorage.getItem(this.contestId + '_CommitConsent')
      this.isConsent = consent === '1'
    }
    // 步骤进度
    if (localStorage.getItem(this.contestId + '_step') == null) {
      localStorage.setItem(this.contestId + '_step', this.selectActive)
    } else {
      this.selectActive = parseInt(localStorage.getItem(this.contestId + '_step'))
    }
    if (this.form.posterSrc === '') {
      this.isPicUpload = false
    }
    if (this.form.modelSrc === '') {
      this.isModelUpload = false
    }
    if (this.form.videoSrc === '') {
      this.isVideoUpload = false
    }
    // contestId
    this.form.contestId = parseInt(this.$route.params.contestId)
  },
  methods: {
    // 上一步
    goForward() {
      this.setForm()
      this.selectActive--
      localStorage.setItem(this.contestId + '_step', this.selectActive)
      localStorage.getItem(this.contestId + '_EnrollForm', this.form)
      if (this.selectActive === 2) {
        const consent = localStorage.getItem(this.contestId + '_CommitConsent')
        this.isConsent = consent === '1'
      }
    },
    // 下一步
    goBackward() {
      const step = this.selectActive + 1
      // 如果当前在承诺书界面，则需要先判断是否勾选承诺
      if (step === 3 && !this.isConsent) {
        this.$notify({
          title: '警告',
          message: '请确认承诺',
          type: 'warning'
        })
      } else {
        this.setForm()
        this.selectActive = step
        if (this.isConsent === true) {
          localStorage.setItem(this.contestId + '_CommitConsent', 1)
        }
        localStorage.setItem(this.contestId + '_step', this.selectActive)
      }
    },
    // 将form存到localstorage里
    setForm() {
      const str = JSON.stringify(this.form)
      localStorage.setItem(this.contestId + '_EnrollForm', str)
    },
    // 将form从localStorage中取出
    getForm() {
      const str = localStorage.getItem(this.contestId + '_EnrollForm')
      this.form = JSON.parse(str)
    },
    // 保存信息,同时要清空localStorage
    async onSave() {
      if (!this.isConsent) {
        this.$message('请先同意承诺书')
        return
      }

      this.form.id = null
      this.form.birthday = dayjs(this.form.birthday).format('YYYY-MM-DD')
      this.form.catagory = this.form.category
      // 获得比赛信息
      await fetchContestInfo(this.form.contestId).then((Response) => {
        this.form.contestName = Response.data.contestName
        this.form.acceptance = Response.data.commitmentTemplate
      })
      console.log(this.form)
      await registration(this.form, this.globalGetUsername()).then((Response) => {
        this.form.id = Response.data.id
      })
      this.$message.success('报名成功！')
      this.selectActive++
      this.clearLocalStorage()
    },
    // 清空localStorage
    clearLocalStorage() {
      localStorage.removeItem(this.contestId + '_EnrollForm')
      localStorage.removeItem(this.contestId + '_CommitConsent')
      localStorage.removeItem(this.contestId + '_step')
    },
    // 去”我的报名信息查看界面“
    goCheckEnrollInfo() {
      this.$router.push(`/mycontest/enrollInfo/${this.form.contestId}`)
    }
  }
}
</script>

<style>
/* 去掉自定义图标上面的圆圈包裹 */
.el-step__icon.is-text {
  border: none;
}

.el-step__title {
  font-size: 14px;
  line-height: 20px;
}

/* 修改默认线条颜色 */
.el-step__line {
  background-color: #67c23a;
}

/* 对于已经完成 is-finished 状态的样式修改 */
.el-step__head.is-finish {
  border-color: #67c23a;
}

/* 图标颜色 */
.el-step__head.is-finish i {
  color: #67c23a;
}

/* 字体颜色 */
.el-step__title.is-finish {
  color: #67c23a;
}

/* 修改正在进行 is-processing 的状态样式 */
.el-step__head.is-process .el-step__line {
  border-top: dotted #e6a23c;
  background: transparent;
}

.el-step__head.is-process i {
  color: #e6a23c;
}

.el-step__title.is-process {
  color: #e6a23c;
}

/* 修改正在等待 is-wait 状态的样式 */
.el-step__head.is-wait .el-step__line {
  border-top: dotted #797878;
  background: transparent;
}

.el-step__head.is-wait i {
  color: #797878;
}

.el-step__title.is-wait {
  color: #797878;
}
</style>

<style scoped>
@import "~@/styles/theme.css";

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.steps {
  display: inline-block;
  background-color: #fff;
  width: 1000px;
  height: 80px;
  margin: auto;
  margin-top: 30px;
  padding: 20px 100px;
  /* padding-top: 25px; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border: 1px solid red; */
}

.info-box {
  display: inline-block;
  margin: auto;
  width: 1000px;
  /* height: 560px; */
  margin-top: 15px;
  margin-bottom: 30px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title {
  display: inline-block;
  width: 90%;
  height: 60px;
  margin: auto;
  /* height: 80px; */
  border-bottom: 1px solid var(--background-color);
  text-align: left;
  padding-top: 20px;
}

.title-text {
  display: inline-block;
  font-size: 20px;
  margin-left: 5px;
}

.body {
  display: inline-block;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  /* border: 1px solid red; */
  min-height: 390px;
}

.go-next {
  display: inline-block;
  width: 500px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  /* border: 1px solid red; */
}

.icon {
  display: inline-block;
  /* height: 40px; */
  /* line-height: 40px; */
  /* padding-top: 5px; */
  /* padding-top:  */
  /* border: 1px solid red; */
}

.icon:hover {
  cursor: pointer;
}

.btn {
  width: 120px;
  margin-left: 30px;
  margin-right: 30px;
}

.el-button {
  border-radius: 15px;
}
</style>
