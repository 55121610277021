<template>
  <div>
    <div class="title">承诺书</div>
    <div class="content">
      <el-input
        v-model="commitmentContent"
        :rows="12"
        class="commit"
        disabled
        type="textarea"
      />
      <!-- {{ commitmentContent }} -->
    </div>
    <div class="check-box">
      <span class="announce">
        <el-checkbox
          v-model="isConsent"
          class="check svg"
          @change="makeCommit"
        >我同意并做出上述承诺</el-checkbox>
      </span>
    </div>
  </div>
</template>

<script>
import { fetchContestInfo } from '@/api/contest'

export default {
  name: 'Commitment',
  props: {
    preIsConsent: {
      type: Boolean
    }
  },
  data() {
    return {
      commitmentContent: '',
      isConsent: false
    }
  },
  mounted() {
    this.isConsent = this.preIsConsent
    this.retrieveCommitment()
  },
  methods: {
    retrieveCommitment() {
      fetchContestInfo(this.$route.params.contestId).then((Response) => {
        this.commitmentContent = Response.data.commitmentTemplate
      })
    },
    makeCommit() {
      // console.log(this.isConsent)
      this.$emit('isConsent', this.isConsent)
    }
  }
}
</script>

<style scoped>

.commit /deep/ .el-textarea__inner {
  border: none;
  background-color: #fff;
  color: #000;
  height: 280px;
}

.title {
  text-align: center;
  font-size: 20px;
  /* font-weight: 800; */
  height: 40px;
  /* border: 1px solid pink; */
  line-height: 40px;
}

.content {
  display: inline-block;
  width: 80%;
  height: 280px;
  /* border: 1px solid pink; */
  margin: auto;
  margin-top: 20px;
  text-align: left;
}

.check-box {
  /* width: 200px; */
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

.announce {
  display: inline-block;
}

.check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: var(--finish-color);
  border-color: var(--finish-color);
  border-radius: 50%;
}

.check /deep/ .el-checkbox__inner {
  border-radius: 50%;
}

.check /deep/ .el-checkbox__label {
  font-size: 12px;
}

.svg {
  float: left;
  /* display: inline-block; */
  /* border: 1px solid white; */
  margin-right: 5px;
  margin-top: 7px;
}
</style>
