<template>
  <div class="info-container">
    <el-form
      v-if="loadingFinished"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      size="small"
      class="form"
    >
      <el-form-item label="作品名称：" prop="workName">
        <el-input
          v-model="form.workName"
          placeholder="请输入作品名称"
        />
      </el-form-item>
      <el-row>
        <el-col :span="9">
          <el-form-item label="作者姓名：" prop="authorName">
            <el-input
              v-model="form.authorName"
              placeholder="请输入作品姓名"
            />
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="出生日期：" prop="birthday">
            <el-date-picker
              v-model="form.birthday"
              style="width: 100%"
              type="date"
              placeholder="选择出生日期"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="性别：" label-width="70px" prop="gender">
            <el-radio-group v-model="form.gender">
              <el-radio label="男" />
              <el-radio label="女" />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="身份证号：" prop="identityNumber">
            <el-input
              v-model="form.identityNumber"
              placeholder="请输入身份证号"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学生类别：" prop="studentType">
            <el-select
              v-model="form.studentType"
              class="width"
              placeholder="请选择学生类别"
            >
              <el-option
                v-for="item in studentTypeList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="手机号码：" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入手机号码"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="作者邮箱：" prop="email">
            <el-input
              v-model="form.email"
              placeholder="请输入作者邮箱"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="小组成员：">
        <el-select
          v-model="form.groupMember"
          multiple
          filterable
          allow-create
          style="width: 100%"
          placeholder="输入文字之后点击下方即可添加成员"
          @change="showInfo"
        >
          <el-option
            v-for="category in []"
            :key="category.value"
            :label="category.name"
            :value="category.value"
          />
        </el-select>
      </el-form-item>

      <el-row>
        <el-col :span="12">
          <el-form-item label="参赛通道：" prop="racetrack">
            <el-select
              v-model="form.racetrack"
              class="width"
              placeholder="请选择参赛通道"
            >
              <el-option
                v-for="item in racetrackList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="参赛类别：" prop="category">
            <el-select
              v-model="form.category"
              class="width"
              placeholder="请选择参赛类别"
            >
              <el-option
                v-for="item in categoryList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="所属高校：" prop="university">
            <el-select
              v-model="form.university"
              class="width"
              filterable
              placeholder="请选择所属高校"
            >
              <el-option
                v-for="item in universityList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所在院系：" prop="department">
            <el-input
              v-model="form.department"
              placeholder="请输入所在院系"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="所在年级：" prop="grade">
            <el-input
              v-model="form.grade"
              placeholder="请输入所在年级"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="作者学号：" prop="studentNumber">
            <el-input
              v-model="form.studentNumber"
              placeholder="请输入作者学号"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="指导老师：" prop="teacherName">
        <el-select
          v-model="form.teacherName"
          multiple
          filterable
          allow-create
          style="width: 100%"
          placeholder="输入文字之后点击下方即可添加成员"
          @change="showInfo"
        >
          <el-option
            v-for="category in []"
            :key="category.value"
            :label="category.name"
            :value="category.value"
          />
        </el-select>
        <!-- <el-input
          v-model="form.teacherName"
          placeholder="请输入指导老师"
        /> -->
      </el-form-item>

      <el-form-item label="通讯地址：">
        <el-input
          v-model="form.address"
          placeholder="请输入通讯地址"
        />
      </el-form-item>

      <el-form-item>
        <div class="go-next">
          <!-- <div v-if="selectActive!=0" class="icon" @click="goForward">
            <svg-icon name="forward" width="50" height="20"></svg-icon>
          </div> -->
          <!-- <div class="icon">
            <svg-icon width="50" height="20"></svg-icon>
          </div> -->
          <!-- <el-button type="primary" size="medium" class="btn" @click="onSave('form')">
            上一步
          </el-button> -->
          <el-button
            type="primary"
            size="medium"
            class="btn"
            @click="goBackward('form')"
          >
            下一步
          </el-button>
          <!-- <div class="icon" @click="goBackward">
            <svg-icon name="backward" width="50" height="20"></svg-icon>
          </div> -->
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getUniversityList } from '@/utils/universities.js'
import { retrieveContestCategory } from '@/api/contest'
import { validator } from '@/utils/validator'
import '@/icons/forward'
import '@/icons/backward'

export default {
  name: 'WorkInfo',
  props: {
    preform: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loadingFinished: false, // 判断获取完数据之后再显示
      rules: {
        // 作品名称
        workName: [
          { required: true, message: '请输入作品名称', trigger: 'blur' }
        ],
        // 作者姓名
        authorName: [
          { required: true, message: '请输入作者姓名', trigger: 'blur' }
        ],
        // 出生日期
        birthday: [
          { required: true, message: '请选择出生日期', trigger: 'change' }
        ],
        // 姓别
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        // 身份证号
        identityNumber: [
          {
            validator: validator.identityNumberCheck,
            required: true,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }
        ],
        // 学生类别
        studentType: [
          { required: true, message: '请选择学生类别', trigger: 'change' }
        ],
        // 手机号码
        phone: [{ validator: validator.phoneNumber, required: true, message: '请输入正确的手机号码', trigger: 'blur' }],
        // 作者邮箱
        email: [{ validator: validator.emailValue, required: true, message: '请输入正确的邮箱', trigger: 'blur' }],
        // 参赛通道
        racetrack: [
          { required: true, message: '请选择参赛通道', trigger: 'change' }
        ],
        // 参赛类别
        category: [
          { required: true, message: '请选择参赛类别', trigger: 'change' }
        ],
        // 所属高校
        university: [
          { required: true, message: '请选择所属高校', trigger: 'change' }
        ],
        // 所在院系
        department: [
          { required: true, message: '请输入所在院系', trigger: 'blur' }
        ],
        // 所在年级
        grade: [{ required: true, message: '请输入所在年级', trigger: 'blur' }],
        // 作者学号
        studentNumber: [
          { required: true, message: '请输入作者学号', trigger: 'blur' }
        ],
        // 指导教师
        teacherName: [
          { required: true, message: '请输入指导教师', trigger: 'blur' }
        ]
      },

      form: null,
      studentTypeList: ['本科生', '研究生'],
      racetrackList: ['主赛道', '专项赛道'],
      categoryList: ['分类一', '分类二', '分类三'],
      universityList: null
    }
  },
  created() {
    this.getUniversities()
  },
  mounted() {
    this.form = Object.assign({}, this.preform)
    this.loadingFinished = true
    this.init()
  },
  methods: {
    showInfo() {
      console.log('group member', this.form.groupMember)
    },

    // 获取所有学校列表
    getUniversities() {
      this.universityList = getUniversityList()
    },
    // 下一步
    goBackward(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('form', this.form)
          this.$parent.goBackward()
        } else {
          // console.log("error submit");
        }
      })
    },
    // 获得比赛的类别
    init() {
      retrieveContestCategory(this.$route.params.contestId).then(Response => {
        this.racetrackList = [Response.data.racetrack]
        this.categoryList = Response.data.category
      })
    }
    // 保存信息
    // onSave(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       console.log('form', this.form)
    //       this.$emit('form', this.form)
    //       // alert('submit');
    //       this.$parent.goBackward()
    //     } else {
    //       console.log('error submit')
    //     }
    //   })
    // }
  }
}
</script>

<style>
.el-form-item--small.el-form-item {
  margin-bottom: 12px !important;
}

.el-form-item__label {
  color: #000000;
  font-weight: 400;
}

.el-radio__label {
  color: #000000;
  font-weight: 400;
}

.el-input__inner {
  border: 1px solid #9c9c9c;
}

.el-radio__inner {
  border: 1px solid #9c9c9c;
}

.el-input__inner::placeholder {
  color: rgba(112, 112, 112, 0.7);
}

/* 谷歌 */
.el-input__inner::-webkit-input-placeholder {
  color: rgba(112, 112, 112, 0.7);
}

/* 火狐 */
.el-input__inner:-moz-placeholder {
  color: rgba(112, 112, 112, 0.7);
}

/*ie*/
.el-input__inner:-ms-input-placeholder {
  color: rgba(112, 112, 112, 0.7);
}
</style>

<style scoped>
.info-container {
  display: inline-block;
  width: 80%;
  /* height: 280px; */
  margin: auto;
  /* border: 1px solid pink; */
}

.width {
  width: 100%;
}

.go-next {
  display: inline-block;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  /* border: 1px solid red; */
}

.icon {
  display: inline-block;
  /* height: 40px; */
  /* line-height: 40px; */
  /* padding-top: 5px; */
  /* padding-top:  */
  /* border: 1px solid red; */
}

.icon:hover {
  cursor: pointer;
}

.btn {
  width: 120px;
  margin-left: 0px;
  margin-right: 30px;
}

.el-button {
  border-radius: 15px;
}
</style>
