<template>
  <div>
    <!-- 报名表 -->
    <div v-loading="loading" class="download_container">
      <!-- 下载报名表 布局 -->
      <!-- <div> -->
      <div class="board-view">
        <el-image
          class="board-img"
          fit="contain"
          :src="posterTinySrc"
          :preview-src-list="[posterSrc]"
          lazy
        />
      </div>
      <!-- <span class="board-text">预览平面板图</span> -->
      <!-- </div> -->
      <div class="btn-place">
        <!-- 下载 图标 -->
        <!-- <svg-icon
          name="download"
          height="40"
          color="#1b1b1b"
          class="svg-icon-download"
          style=""
        /> -->
        <!-- 预览按钮 -->
        <el-button
          class="btn-download poster"
          @click="onShowCover"
        >预览平面图
        </el-button>
        <!-- 预览 按钮 -->
        <el-button class="btn-preview poster" @click="onShowCover">
          <i class="el-icon-view" />预览
        </el-button>
        <!-- 下载 按钮 -->
        <el-button
          class="btn-download"
          @click="downloadFile"
        >下载报名表
        </el-button>
        <!-- 预览 按钮 -->
        <el-button class="btn-preview" @click="previewFile">
          <i class="el-icon-view" />预览
        </el-button>
      </div>

      <!-- 文字提示：点击这里下载 -->
      <!-- <div class="tip_download1" @click="downloadFile">点击这里下载</div> -->
      <!-- 文字提示：支持扩展名：jpg; png; PDF -->
      <!-- <div class="tip_download2">
        支持扩展名：jpg; png; PDF
      </div> -->
      <!-- 右上角标签图标 -->
      <!-- <div class="tag_right_top_line">
        占用
      </div> -->
      <!-- <div class="tag_right_top">
        <p>未盖章</p>
      </div> -->

      <!-- 右上角图标：有间隙 -->
      <!-- <div class="mask" />
      <div class="mask_content">未盖章</div> -->
    </div>

    <!-- 作品预览图展示 -->
    <el-dialog :visible.sync="imageShow" class="image-preview">
      <el-image style="width: 100%; height: 100%" :src="posterSrc" />
    </el-dialog>
  </div>
</template>

<script>
import '@/icons/upload'
import '@/icons/download'
import { retrieveWork, isExist } from '@/api/contest'
import { retrieveRegistrationInfo } from '@/api/work'
import { pr } from '@/utils/preview'

export default {
  name: 'Form',
  props: {
    workId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 是否展示预览图
      imageShow: false,

      loading: true,
      posterSrc: 'http://www.bjuidc.ncut.edu.cn/api/file/preview-tiny/pictures/contest_24/6089962135.jpg',
      posterTinySrc: ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.getPosterSrc()
    }, 5000)
  },
  methods: {
    // 预览平面版式
    getPosterSrc() {
      retrieveRegistrationInfo(this.workId).then(Response => {
        const posterSrc = Response.data.posterSrc
        this.posterSrc = this.PR.previewOrigin(posterSrc)
        this.posterTinySrc = this.PR.previewTiny(posterSrc)
        this.loading = false
      })
    },
    // 显示作品封面图
    onShowCover() {
      this.imageShow = !this.imageShow
      // this.imageSelectSrc = pr.previewTiny(coverUrl)
    },
    // 下载文件
    downloadFile() {
      this.previewFile()
    },
    // 预览文件
    async previewFile() {
      let count = 3
      var flag = false
      var src = ''
      await retrieveWork(this.workId).then((Response) => {
        src = Response.data.applicationSrc
      })
      const interval = setInterval(async function() {
        if (flag === false && count <= 0) {
          this.$message.error('错误请联系管理员')
        }
        if (count <= 0 || flag) {
          clearInterval(interval)
          return
        }
        await isExist(src).then((Response) => {
          if (Response.data) {
            flag = true
            window.open(pr.previewOrigin(src))
          } else {
            count--
          }
        })
      }, 1000)
    },
    uploadSuccess(response, file, fileList) {
      console.log('upload success', response)
      console.log('upload success', file)
      console.log('upload success', fileList)
      alert('上传成功')
    },
    uploadError(err, file, fileList) {
      console.log('upload error', err)
      console.log('upload error', file)
      console.log('upload error', fileList)
      alert('上传失败')
    }
  }
}
</script>

<style scoped>
@import "~@/styles/theme.css";

/* .download-contain {
  text-align: center;
} */

/* 下载报名表 容器 */
.download_container {
  width: 350px;
  height: 200px;
  display: inline-block;
  margin: auto;
  margin-top: 20px;
  border: 1px solid #595159;
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

/* 预览平面版图 */
.board-view {
  display: inline-block;
  width: 90px;
  text-align: right;
}

.board-img {
  height: 100%;
}

.board-text {
  display: inline-block;
  font-size: 11px;
  text-align: left;
}

/* 下载 图标 */
.svg-icon-upload {
  vertical-align: bottom;
}

.btn-place {
  display: inline-block;
  width: 200px;
}

/* 上传报名表 按钮 */
.btn-upload {
  display: inline-block;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 10px;
  border: none;
  /* 样式相关 */
  background: #6a5cb3;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}

/* 预览按钮 */
.btn-midline {
  display: inline-block;
  /* 设置div里面的文字无法被选中 */
  user-select: none;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border: none;
  border-radius: 0 8px 8px 0;
  /* 样式相关 */
  background: #5fdfc6;
  color: #fff;
  font-size: 14px;
  padding: 15px 15px;
}

/* 提示文字1 */
.tip_download1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_download2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}

/* 右上角有间隙 样式 */
.mask {
  position: absolute;
  top: 20px;
  right: -8px;
  width: 60px;
  height: 5px;
  transform: rotate(45deg);
  border-width: 0px 5px 5px 5px;
  border-style: solid;
  border-color: transparent transparent #a2a2a2 transparent;
}

.mask_content {
  position: absolute;
  top: 26px;
  right: -24px;
  width: 125px;
  height: 25px;
  transform: rotate(45deg);
  border-width: 0px 25px 25px 25px;
  border-style: solid;
  border-color: transparent transparent #a2a2a2 transparent;
  color: white;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

/* 上传报名表 容器 */
.upload_container {
  border: 1px solid #817481;
  border-radius: 5px;
  padding-top: 70px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

/* 下载 图标 */
.svg-icon-download {
  transform: rotate(180deg);
  vertical-align: top;
}

/* 下载报名表 按钮 */
.btn-download {
  /* 定位相关 */
  vertical-align: top;
  height: 35px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 10px;
  margin-top: -40px;
  border: none;
  /* 样式相关 */
  background: var(--background-color);
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 5px 20px;
}

/* 预览按钮 */
.btn-preview {
  /* 定位相关 */
  vertical-align: top;
  height: 35px;
  border: none;
  border-radius: 0 8px 8px 0;
  margin-top: -40px;
  /* 样式相关 */
  background: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 5px 5px;
}

.poster {
  margin-top: -70px;
}

/* 提示文字1 */
.tip_upload1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_upload2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}
</style>
